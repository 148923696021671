<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <b-card v-if="$can('create', 'Resource')">
        <b-card-title>
          {{ $t('page_titles.add_learning_resource') }}
        </b-card-title>
        <b-card-body>
          <b-row>
            <b-col cols="12">
              <add-learning-resource-form
                @success="onSuccess"
                @error="onError"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card>
        <b-card-title v-if="$can('create', 'Resource')">
          {{ $t('page_titles.learning_resources') }}
        </b-card-title>
        <b-card-body class="overflow-x-hidden">
          <b-row>
            <b-col cols="12">
              <learning-resources-table
                @success="onSuccess"
                @error="onError"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCol, BRow, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import LearningResourcesTable from '@/views/components/tables/LearningResourcesTable.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddLearningResourceForm from '@/views/components/forms/AddLearningResourceForm.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    LearningResourcesTable,
    AddLearningResourceForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
    onSuccess(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: event.message,
        },
      })
    },
    onError(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: event.message,
        },
      })
    },
  },
  metaInfo() {
    return { title: this.$t('page_titles.learning_resources'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

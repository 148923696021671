<template>
  <div>
    <b-row
      v-if="showSearch"
    >
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Tag"
          label-for="tag-select"
        >
          <v-select
            v-model="tagFilter"
            :options="tags"
            :clearable="false"
            name="tag-select"
          />
        </b-form-group>
        <b-form-group
          label="Filter by Document Type"
          label-for="document-select"
        >
          <v-select
            v-model="resourceTypeFilter"
            :options="resourceTypes"
            :clearable="false"
            name="document-select"
          />
        </b-form-group>
      </b-col>

      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Search"
          label-for="filter-input"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              name="filter-input"
              type="search"
              placeholder="Type to Search"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-alert
      :show="false"
      variant="info"
      class="mb-50"
    >
      <div class="alert-body text-center">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span>Below is a collection of curated digital learning resources designed to empower you and enhance your knowledge.</span>
      </div>
    </b-alert>
    <b-alert
      :show="true"
      variant="warning"
      class="mb-50"
    >
      <div class="alert-body text-center">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span>All legal information is legal information, NOT legal advice. Please seek a lawyer for legal advice. Some resources may qualify for CPD points, however, you must check with your regulatory body to confirm what is acceptable. Access to public videos are provided as a courtesy and brought to you for easy access, all other resources are at the benefit of your subscription.</span>
      </div>
    </b-alert>
    <b-table
      :fields="fields"
      :items="filteredResourceFiles"
      :responsive="true"
      striped
      bordered
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="mt-2"
      @filtered="onFiltered"
    >
      <template #cell(resource_type)="data">
        {{ data.value | capitalize }}
      </template>
      <template #cell(tags)="data">
        <b-badge
          v-for="(tag, index) in data.item.tags"
          :key="index"
          variant="primary"
        >
          {{ tag }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        {{ data.value | moment('LLL') }}
      </template>
      <template #cell(actions)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          @click="fetchResourceLink(data.item.uuid)"
        >
          <feather-icon
            icon="ExternalLinkIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.open') }}</span>
        </b-button>
        <b-button
          v-if="$can('update', 'Resource')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          @click="updateResource(data.item.uuid)"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.edit') }}</span>
        </b-button>
        <b-button
          v-if="$can('delete', 'Resource')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="m-1"
          @click="deleteResource(data.item.uuid)"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.delete') }}</span>
        </b-button>
      </template>
      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value || 'N/A' }}
      </template>
    </b-table>
    <p
      v-if="filteredResourceFiles.length == 0"
      class="text-center"
    >
      {{ $t('no_data') }}
    </p>
    <b-col
      cols="12"
    >
      <b-pagination
        v-show="filteredResourceFiles.length > 0"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <edit-resource-modal
      v-if="$can('update', 'Resource')"
      @success="editSuccess"
      @error="editError"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTable,
  BPagination,
  BBadge,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import EditResourceModal from '@/views/components/modal/EditResourceModal.vue'

export default {
  components: {
    BAlert,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    EditResourceModal,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      // eslint-disable-next-line no-param-reassign
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'title', label: 'Title', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'resource_type', label: 'Resource Type', sortable: true },
        { key: 'tags', label: 'Tags', sortable: true },
        { key: 'created_at', label: 'Date Added', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      filter: null,
      totalRows: 1,
      tagFilter: { label: 'All', value: 'all' },
      resourceTypeFilter: { label: 'All', value: 'all' },
    }
  },
  computed: {
    /* eslint-disable no-plusplus */
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('resource', ['resourceFiles', 'selectedResource']),
    tags() {
      const tagOptions = [{
        label: 'All',
        value: 'all',
      }]
      const tagSet = new Set()
      for (let i = 0; i < this.resourceFiles.length; i++) {
        for (let j = 0; j < this.resourceFiles[i].tags.length; j++) {
          tagSet.add(this.resourceFiles[i].tags[j])
        }
      }
      const tagArray = Array.from(tagSet)
      for (let i = 0; i < tagArray.length; i++) {
        const tag = {
          label: tagArray[i],
          value: tagArray[i],
        }
        tagOptions.push(tag)
      }
      return tagOptions
    },
    resourceTypes() {
      const resourceTypeOptions = [{
        label: 'All',
        value: 'all',
      }]
      const resourceTypeSet = new Set()
      for (let i = 0; i < this.resourceFiles.length; i++) {
        resourceTypeSet.add(this.resourceFiles[i].resource_type)
      }
      const resourceTypeArray = Array.from(resourceTypeSet)
      for (let i = 0; i < resourceTypeArray.length; i++) {
        const resourceType = {
          label: resourceTypeArray[i],
          value: resourceTypeArray[i],
        }
        resourceTypeOptions.push(resourceType)
      }
      return resourceTypeOptions
    },
    resourceFileTypes() {
      const types = []
      types.push({
        label: 'Document',
        value: 'document',
      })
      types.push({
        label: 'Image',
        value: 'image',
      })
      types.push({
        label: 'Video',
        value: 'video',
      })
      types.push({
        label: 'Audio',
        value: 'audio',
      })
      return types
    },
    filteredResourceFiles() {
      try {
        if (this.tagFilter.value === 'all' && this.resourceTypeFilter.value === 'all') {
          return this.resourceFiles
        }
        if (this.resourceTypeFilter.value === 'all' && this.tagFilter.value !== 'all') {
          return this.resourceFiles.filter(u => u.tags.includes(this.tagFilter.value))
        }
        if (this.resourceTypeFilter.value !== 'all' && this.tagFilter.value === 'all') {
          return this.resourceFiles.filter(u => u.resource_type === this.resourceTypeFilter.value)
        }
        return this.resourceFiles.filter(u => u.tags.includes(this.tagFilter.value) && u.resource_type === this.resourceTypeFilter.value)
      } catch (e) {
        console.warn('Could not apply filter')
        return this.resourceFiles
      }
    },
  },
  async created() {
    try {
      await this.getResourceFiles()
    } catch (e) {
      console.debug(`Error in created() of LearningResourcesTable.vue: ${e.message}`)
    }
  },
  mounted() {
    this.totalRows = this.filteredResourceFiles.length
  },
  methods: {
    ...mapActions('resource', [
      'getResourceFiles',
      'getResourceFile',
      'deleteResourceFile',
      'createResourceFile',
      'getResourceFileDownloadURL',
      // 'getTags'
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async updateResource(uuid) {
      try {
        await this.getResourceFile(uuid)
        // show the modal manually
        this.$nextTick(() => {
          this.$bvModal.show('edit-resource-modal')
        })
      } catch {
        console.debug(`Cannot edit resource with uuid: ${uuid}`)
      }
    },
    fetchResourceLink(uuid) {
      try {
        const windowReference = window.open('about:blank', '_blank')
        this.getResourceFileDownloadURL(uuid).then(response => {
          windowReference.location = response.data.download_url
        })
      } catch (e) {
        console.log(e.message)
        this.$emit('error', { message: 'Oops! Could not open the resource link. Please try again.' })
      }
    },
    deleteResource(uuid) {
      try {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.deleteResourceFile(uuid)
              this.$emit('success', { message: 'The resource was successfully deleted' })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch (e) {
        console.debug(`Error deleting resource: ${e.message}`)
        this.$emit('error', { message: 'Oops! Something went wrong when trying to delete the resource. Please try again.' })
      }
    },
    editSuccess(event) {
      this.$emit('success', event)
    },
    editError(event) {
      this.$emit('error', event)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-tabs v-model="tabIndex">
    <b-tab>
      <template #title>
        <feather-icon icon="UploadCloudIcon" />
        <span>Upload a PDF Document or Image</span>
      </template>
      <validation-observer
        ref="uploadResourceForm"
        #default="{invalid}"
      >
        <b-overlay
          :show="loading"
          :variant="overlayVariant"
          :opacity="overlayOpacity"
          rounded="sm"
        >
          <b-form
            class="mt-2"
            @submit.prevent="submitUploadForm"
          >
            <!-- File -->
            <b-form-group
              label="Choose a file"
              label-for="file"
            >
              <validation-provider
                #default="{ errors }"
                name="File"
                vid="file"
                rules="required"
              >
                <b-form-file
                  id="filez"
                  v-model="file"
                  name="file"
                  no-drop
                  accept="image/*, application/pdf, audio/*"
                  :state="errors.length > 0 ? false:null"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  @change="handleFileChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Filename -->
            <b-form-group
              v-if="file"
              label="Filename"
              label-for="filename"
            >
              <b-form-input
                id="filename"
                v-model="resourceFile.object_name"
                disabled
                name="filename"
                data-vv-validate-on="blur"
              />
            </b-form-group>

            <!-- Title -->
            <b-form-group
              v-if="file"
              label="Title"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                :rules="{ regex: /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,100}((?![\^!@#$*~ <>?]).)$/, required: true }"
              >
                <b-form-input
                  id="title"
                  v-model="resourceFile.title"
                  :state="errors.length > 0 ? false:null"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  name="title"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Description (Optional) -->
            <b-form-group
              v-if="file"
              label="Description (Optional)"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="resourceFile.description"
                name="description"
                data-vv-validate-on="blur"
              />
            </b-form-group>

            <!-- Tags -->
            <b-form-group
              v-if="file"
              label="Categories / Tags"
              label-for="tags-select"
            >
              <v-select
                id="tags-select"
                v-model="resourceFile.tags"
                :options="tags"
                taggable
                multiple
                :clearable="false"
                placeholder="Choose from the list or start typing to create your own"
                :create-option="tag => ({ name: tag })"
                label="name"
                name="tags"
              />
            </b-form-group>

            <!-- server alert -->
            <b-alert
              v-height-fade.appear
              class="mt-1"
              variant="danger"
              :show="serverError !== null"
            >
              <div
                class="alert-body"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span v-html="serverError" />
              </div>
            </b-alert>

            <!-- submit buttons -->
            <b-button
              class="mt-1"
              type="submit"
              variant="primary"
              :disabled="invalid || loading"
            >
              <span>{{ $t('buttons.submit') }}</span>
            </b-button>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="GlobeIcon" />
        <span>Add Media from the Internet</span>
      </template>
      <validation-observer
        ref="addMediaForm"
        #default="{invalid}"
      >
        <b-overlay
          :show="loading"
          :variant="overlayVariant"
          :opacity="overlayOpacity"
          rounded="sm"
        >
          <b-form
            class="mt-2"
            @submit.prevent="submitMediaForm"
          >

            <!-- Title -->
            <b-form-group
              label="Title"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                :rules="{ regex: /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,100}((?![\^!@#$*~ <>?]).)$/, required: true }"
              >
                <b-form-input
                  id="title"
                  v-model="resourceFile.title"
                  :state="errors.length > 0 ? false:null"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  name="title"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Description (Optional) -->
            <b-form-group
              label="Description (Optional)"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="resourceFile.description"
                name="description"
                data-vv-validate-on="blur"
              />
            </b-form-group>

            <!-- URL -->
            <b-form-group
              label="URL"
              label-for="url"
            >
              <validation-provider
                #default="{ errors }"
                name="URL"
                vid="url"
                :rules="{ regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/, required: true }"
              >
                <b-form-input
                  id="url"
                  v-model="resourceFile.download_url"
                  :state="errors.length > 0 ? false:null"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  name="url"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Media Type -->
            <b-form-group
              label="Media Type"
              label-for="media-select"
            >
              <validation-provider
                #default="{ errors }"
                name="Media Type"
                vid="media-select"
                rules="required"
              >
                <v-select
                  id="media-select"
                  v-model="resourceFile.resource_type"
                  :options="resourceFileTypes"
                  :clearable="false"
                  :reduce="rt => `${rt.value}`"
                  name="media-select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Tags -->
            <b-form-group
              label="Categories / Tags"
              label-for="tags-select"
            >
              <v-select
                id="tags-select"
                v-model="resourceFile.tags"
                :options="tags"
                taggable
                multiple
                :clearable="false"
                placeholder="Choose from the list or start typing to create your own"
                :create-option="tag => ({ name: tag })"
                label="name"
                name="tags"
              />
            </b-form-group>

            <!-- server alert -->
            <b-alert
              v-height-fade.appear
              class="mt-1"
              variant="danger"
              :show="serverError !== null"
            >
              <div
                class="alert-body"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span v-html="serverError" />
              </div>
            </b-alert>

            <!-- submit buttons -->
            <b-button
              class="mt-1"
              type="submit"
              variant="primary"
              :disabled="invalid || loading"
            >
              <span>{{ $t('buttons.submit') }}</span>
            </b-button>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BForm, BFormGroup, BOverlay, BButton, BFormInput, BAlert, BFormFile,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  regex,
} from '@validations'
import {
  heightFade,
} from '@core/directives/animations'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormFile,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      loading: false,
      serverError: null,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      required,
      regex,
      file: null,
      resourceFile: {},
      fileError: null,
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters('resource', ['tags']),
    resourceFileTypes() {
      const types = []
      types.push({
        label: 'Document',
        value: 'document',
      })
      types.push({
        label: 'Image',
        value: 'image',
      })
      types.push({
        label: 'Video',
        value: 'video',
      })
      types.push({
        label: 'Audio',
        value: 'audio',
      })
      return types
    },
  },
  watch: {
    tabIndex: {
      handler() {
        this.resetForm()
      },
      // console.log(this.actualTimeSpent);
    },
  },
  async created() {
    await this.getTags()
  },
  methods: {
    ...mapActions('resource', ['getTags', 'createResourceFile']),
    handleFileChange(e) {
      this.serverError = null
      this.resource_file = {}
      const file = e.target.files[0]
      const fileLimit = 25
      // Whenever the file changes, emit the 'input' event with the file data.
      this.$emit('input', file)
      this.file = file
      const fileSize = ((this.file.size / 1024) / 1024).toFixed(4)
      if (fileSize > fileLimit) {
        this.$refs.uploadResourceForm.setErrors({
          file: ['Selected file exceeds 25 MB limit'],
        })
      } else if (!(file.type === 'application/pdf' || file.type.includes('image/'))) {
        this.$refs.uploadResourceForm.setErrors({
          file: ['The selected file is not a supported file type'],
        })
      }
      this.resourceFile.object_name = file.name
    },
    submitUploadForm() {
      this.serverError = null
      this.loading = true
      this.$refs.uploadResourceForm.validate().then(async result => {
        if (result) {
          this.loading = true
          try {
            if (this.file) {
              this.resourceFile.file = this.file
            }
            await this.createResourceFile(this.resourceFile)
            this.$emit('success', { message: 'Resource successfully created' })
            this.resetForm()
          } catch (e) {
            if (e.response && e.response.status < 500) {
              this.serverError = e.response.data.message
            }
            this.$emit('error', { message: 'Could not upload resource' })
          } finally {
            this.loading = false
          }
        }
      })
    },
    submitMediaForm() {
      this.serverError = null
      this.loading = true
      this.$refs.addMediaForm.validate().then(async result => {
        if (result) {
          this.loading = true
          try {
            await this.createResourceFile(this.resourceFile)
            this.$emit('success', { message: 'Resource successfully created' })
            this.resetForm()
          } catch (e) {
            if (e.response && e.response.status < 500) {
              this.serverError = e.response.data.message
            }
            this.$emit('error', { message: 'Could not add resource' })
          } finally {
            this.loading = false
          }
        }
      })
    },
    resetForm() {
      this.file = null
      this.serverError = null
      this.resourceFile = {}
      this.$refs.uploadResourceForm.reset()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-modal
    v-if="selectedResource"
    id="edit-resource-modal"
    :title="$t('page_titles.edit_learning_resource')"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="handleOk"
    @show="resetModal"
    @hidden="resetModal"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <validation-observer
            ref="editResourceForm"
          >
            <b-overlay
              :show="loading"
              :variant="overlayVariant"
              :opacity="overlayOpacity"
              rounded="sm"
            >
              <b-form @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    vid="title"
                    :rules="{ regex: /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,100}((?![\^!@#$*~ <>?]).)$/, required: true }"
                  >
                    <b-form-input
                      id="title"
                      v-model="resourceFile.title"
                      :state="errors.length > 0 ? false:null"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      name="title"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Description (Optional)"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="resourceFile.description"
                    name="description"
                    data-vv-validate-on="blur"
                  />
                </b-form-group>

                <!-- Media Type -->
                <b-form-group
                  label="Media Type"
                  label-for="media-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Media Type"
                    vid="media-select"
                    rules="required"
                  >
                    <v-select
                      id="media-select"
                      v-model="resourceFile.resource_type"
                      :options="resourceFileTypes"
                      :clearable="false"
                      :reduce="rt => `${rt.value}`"
                      name="media-select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Categories / Tags"
                  label-for="tags-select"
                >
                  <v-select
                    id="tags-select"
                    v-model="resourceFile.tags"
                    :options="tags"
                    taggable
                    multiple
                    :clearable="false"
                    placeholder="Choose from the list or start typing to create your own"
                    :create-option="tag => ({ name: tag })"
                    label="name"
                    name="tags"
                  />
                </b-form-group>
              </b-form>

              <!-- server alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="serverError !== null"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span v-html="serverError" />
                </div>
              </b-alert>

            </b-overlay>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BAlert, BOverlay, BModal, BForm, BFormInput, BFormGroup, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
  email,
} from '@validations'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BAlert,
    BOverlay,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BContainer,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      resourceFile: {},
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      required,
      email,
      serverError: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('resource', ['selectedResource', 'tags']),
    resourceFileTypes() {
      const types = []
      types.push({
        label: 'Document',
        value: 'document',
      })
      types.push({
        label: 'Image',
        value: 'image',
      })
      types.push({
        label: 'Video',
        value: 'video',
      })
      types.push({
        label: 'Audio',
        value: 'audio',
      })
      return types
    },
  },
  async created() {
    await this.getTags()
    this.resourceFile = { ...this.selectedResource }
  },
  methods: {
    ...mapActions('resource', ['getTags', 'updateResourceFile']),
    ...mapActions('auth', ['getUserProfile']),

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.editResourceForm.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.serverError = null
            // Submit the form
            await this.updateResourceFile(this.resourceFile)
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('edit-resource-modal')
            })
            this.$emit('success', { message: 'Resource File edited successfully' })
          } catch (e) {
            console.debug(`Submit error in EditResourceModal.vue: ${e.message}`)
            this.serverError = this.$t('errors.generic')
            this.$emit('error', { message: 'Could not edit resource file' })
          } finally {
            this.loading = false
          }
        }
      })
    },
    resetModal() {
      const {
        created_at, updated_at, id, object_name, object_url, download_url, mime_type, role_whitelist, download_url_expires_at, ...payload
      } = this.selectedResource
      this.resourceFile = payload
      this.resourceFile.tags = []
      this.resourceFile.taggings = null
      // populate tags
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedResource.taggings.length; i++) {
        const tag = this.tags.find(t => t.id === this.selectedResource.taggings[i].tag_id)
        this.resourceFile.tags.push(tag)
      }
      this.serverError = null
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border-color: #ea5455 !important;
    }
  }
</style>
